
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>新闻洞察</h3>
      <span>___</span>
      <p>NEWS</p>
    </div>
    <div class="content">
      <h5>新闻洞察</h5>
      <a href="https://mp.weixin.qq.com/s/kuOgaQ7FfR92KUbOiEQ8ug" class="news_c">
        <img class="news_img" src="../../assets/images/new01.png" />
        <div class="news_n">
          <p>08-19   <span> ___</span></p>
          <img src="../../assets/images/new_j.png" />
        </div>
        <div class="news_content">
          <h6>行业资讯丨2022年下半年中国国内旅游市场景气报告 免费领取</h6>
          <p>
            浩华管理顾问公司近期发布了关于《2022年下半年中国国内旅游市场景气报告》。中国旅游产业已经进入饱受新冠疫情冲击的第三个年头，依旧受到此起彼伏的区域性……
          </p>
          <p class="author">作者：微心动运营乌托邦 2022-08-19 17:00</p>
        </div>
      </a>
      <a href="https://mp.weixin.qq.com/s/njyLhnt-7v1GD18xkl1rcg" class="news_c">
        <img class="news_img" src="../../assets/images/new02.png" />
        <div class="news_n">
          <p>06-10   <span> ___</span></p>
          <img src="../../assets/images/new_j.png" />
        </div>
        <div class="news_content">
          <h6>高清下载｜一图详解酒店私域流量运营的六大核心</h6>
          <p>
            润米咨询创始人刘润老师曾提到：私域流量就像井水，打井很贵，但用水免费。公域流量就像自来水，付费用水，价高者得。……
          </p>
          <p class="author">作者：微心动运营乌托邦 2022-06-10 17:00</p>
        </div>
      </a>
      <a href="https://mp.weixin.qq.com/s/gtnQIwrvHlNu2BHgWORBHQ" class="news_c">
        <img class="news_img" src="../../assets/images/new03.png" />
        <div class="news_n">
          <p>06-10   <span> ___</span></p>
          <img src="../../assets/images/new_j.png" />
        </div>
        <div class="news_content">
          <h6>行业资讯丨各地纾困政策频出，疫下旅游企业如何逆势求生？</h6>
          <p>
            今年以来，全国各地陆续复工复产，新一波疫情再次席卷而来，多点散发、波及范围广、疫情防控形势严峻，这也导致在这两年本就遭受重创的旅游业再次陷入停摆。……
          </p>
          <p class="author">作者：微心动运营乌托邦 2022-04-08 17:00</p>
        </div>
      </a>
      <a href="https://mp.weixin.qq.com/s/CMVsORCrJ5bUg9tp22Ytyg" class="news_c">
        <img class="news_img" src="../../assets/images/new04.png" />
        <div class="news_n">
          <p>06-10  <span> ___</span></p>
          <img src="../../assets/images/new_j.png" />
        </div>
        <div class="news_content">
          <h6>行业资讯丨《2022年中国酒店业数字化转型趋势报告—速赢致胜》解读，文末附完整版报告免费下载链接！</h6>
          <p>
            2021年，中国酒店业的云化进程加速、传统企业级技术架构面临颠覆、数据安全重要性加码、单体酒店不断尝试场景+技术的微创新、数字化线上渗透率不断加剧……
          </p>
          <p class="author">作者：微心动运营乌托邦  2022-03-04 17:00</p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/consult02.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  span {
    font-weight: 600;
    font-size: 0.4rem;
  }
  p {
    width: 7.43rem;
    margin: 1em auto 0;
    font-size: 0.36rem;
    line-height: 0.3rem;
  }
}
.content {
  color: #333;
  margin-top: 1.36rem;
  
  padding: 0 2rem;
  h5 {
    font-size: 0.28rem;
    text-align: center;
    padding-bottom: 0.58rem;
  }
  .news_c {
    display: flex;
    padding: 0.6rem 0;
    border-bottom: 0.01rem solid #eeeeee;
    .news_img {
      width: 5.98rem;
      height: 2.56rem;
    }
    .news_n {
      width: 1.5rem;
      padding: 0.31rem 0 0 0.5rem;
      p {
        font-size: 0.28rem;
            color: #333;
        span{
            color: #aaa;
        }
      }
      img {
        margin-top: 0.88rem;
        width: 0.28rem;
        height: 0.28rem;
        padding: 0.05rem;
        background-color: #ffc53d;
        border-radius: 50%;
      }
    }
    .news_content {
      width: 6.32rem;
      padding: 0.23rem 0;
      h6 {
        font-size: 0.27rem;
        line-height: 0.41rem;
        color: #333;
        margin-bottom: 0.16rem;
      }
      p {
        font-size: 0.18rem;
        line-height: 0.27rem;
        color: #666666;
      }
      .author {
        padding-top: 0.3rem;
        text-align: right;
      }
    }
  }
}
</style>
